import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { getAgreedTermsName, type RiskCarrierProductType, type StaticFilesGroup } from '@orus.eu/dimensions'
import {
  CheckboxContainer,
  Text,
  colors,
  getFileDisplayName,
  mobileMediaQuery,
  spacing,
  useLanguage,
  useTranslate,
} from '@orus.eu/pharaoh'
import type { ReactNode } from '@tanstack/react-router'
import { memo, useMemo, useRef } from 'react'
import { useInView } from 'react-intersection-observer'
import {
  getAgreedTermsDraftUrl,
  getCurrentSubscriptionGeneralTermsDocumentDownloadLink,
} from '../../../../lib/download-links'
import { usePermissions } from '../../../../lib/use-permissions'

export type ContractSignatureSectionProps = {
  subscriptionId: string
  generalTermsGroups: StaticFilesGroup[]
  riskCarrierProducts: RiskCarrierProductType[]
  submitButton: ReactNode
  setHasAgreedDocuments: (hasAgreedDocuments: boolean) => void
  hasAgreedDocuments: boolean
}

export const ContractSignatureSection = memo<ContractSignatureSectionProps>(function ContractSignatureSection({
  subscriptionId,
  generalTermsGroups,
  riskCarrierProducts,
  submitButton,
  setHasAgreedDocuments,
  hasAgreedDocuments,
}) {
  const language = useLanguage()
  const translate = useTranslate()
  const buttonRowRef = useRef<HTMLDivElement>(null)
  const { ref: triggerRef } = useInView({
    threshold: 1,
    onChange: (inView) => buttonRowRef.current?.classList?.toggle?.('is-pinned', !inView),
  })

  const files = useMemo(() => generalTermsGroups.flatMap((document) => document.documents), [generalTermsGroups])
  const agreedTermsName = getAgreedTermsName(riskCarrierProducts, language)
  const isPartner = usePermissions().type === 'partner'

  return (
    <>
      <div>
        <CheckboxContainer
          checked={hasAgreedDocuments}
          onChange={() => setHasAgreedDocuments(!hasAgreedDocuments)}
          border={true}
        >
          <Text variant="body2">
            {isPartner
              ? translate('checkout_customer_accepts_document_list')
              : translate('checkout_i_accept_document_list')}{' '}
            <a
              css={css`
                :link {
                  color: ${colors.blue[500]};
                }
              `}
              href={getAgreedTermsDraftUrl(subscriptionId)}
            >
              {agreedTermsName}
            </a>
            {', '}
            {files.map((file, index) => (
              <>
                <a
                  css={css`
                    :link {
                      color: ${colors.blue[500]};
                    }
                  `}
                  key={index}
                  href={getCurrentSubscriptionGeneralTermsDocumentDownloadLink({
                    subscriptionId,
                    file,
                  })}
                >
                  {getFileDisplayName(file.fileName)}
                </a>
                {index + 1 === files.length ? '.' : ', '}
              </>
            ))}
          </Text>
        </CheckboxContainer>
      </div>
      <div>
        <div ref={triggerRef}></div>
        <ButtonRowDiv ref={buttonRowRef}>{submitButton}</ButtonRowDiv>
      </div>
    </>
  )
})

const ButtonRowDiv = styled.div`
  margin-top: ${spacing[70]};
  text-align: right;

  &.is-pinned .next-button {
    &:not(:disabled) {
      position: fixed;
      bottom: ${spacing[60]};
      right: ${spacing[50]};
      z-index: 1000;
    }
  }

  ${mobileMediaQuery} {
    text-align: center;

    &.is-pinned .next-button {
      &:not(:disabled) {
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;

        border-radius: 0;
      }
    }
  }
`
